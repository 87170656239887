.table-input-puretone {
    width: 100px !important;
    margin: 0px auto;
}

.table-input-logo {
    width: 200px !important;
    margin: 0px auto;
}

.table-input-noise {
    width: 150px !important;
    margin: 0px auto;
}

.newGainColumns {
    background-color: rgb(0, 128, 0, .4) !important;
}

.oldGainColumns {
    background-color: rgb(255, 0, 0, .4) !important;
}