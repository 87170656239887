.inventoryMenu {
    color: #0DCAF0 !important;
    background-color: white !important;
}

.inventoryMenu:hover {
    color: white !important;
    background-color: #0DCAF0 !important;
}

.inventory-th {
    width: 10px !important;
}