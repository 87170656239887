#workOrdersHeader button,
.convertButtons {
    background: linear-gradient(to right, var(--audiometer-green), var(--audiometer-blue));
    border: none;
    border-radius: 5px;
    background-color: white;
    transition: 300ms all;
    color: white;
}

#workOrdersHeader button:hover,
.convertButtons:hover {
    box-shadow: 0 0 3px var(--audiometer-green);
}

.conversionConfirmToast{
    width: 500px;
}

.distCenterAccordionButton{
    border: none;
    text-align: center;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1rem;
    border-radius: 10px;
    background: linear-gradient(to right, var(--audiometer-green), var(--audiometer-blue));
    color: #fff;
    box-shadow: 1px 1px 2px rgb(123, 123, 123);
}

.detailColumn{
    width: 40%;
    overflow: scroll;
}

@media (max-width: 530px){
    .conversionConfirmToast{
        width: 300px;
    }
}

.white-custom-button{
    background: rgba(var(--bs-white-rgb),var(--bs-bg-opacity))!important;
}