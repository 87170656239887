/* ORDER STATUS */
.canceled-order {
    background-color: #fe4747 !important;
}

.paid-order {
    background-color: #00b784 !important;
}

.accepted-order {
    background-color: #69e2ff !important;
}

.assembling-order {
    background-color: #0081cb !important;
}

.sended-order {
    background-color: #43eba2 !important;
}