#principalBar {
    box-shadow: 1px 1px 3px gray;
}

#headerVersion {
    font-size: 10px;
}

#logo {
    height: 30px;
}

.logout {
    cursor: pointer;
    transition: all 300ms;
}

.logout:hover {
    background-color: #0dcaf0;
    color: white;
}