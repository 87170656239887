@media (max-width: 616px) {
    .second-title {
        color: #00b0ff;
    }
}

@media (max-width: 616px) {
    .list-clients {
        display: block;
    }

    .actions {
        transform: scale(1.5);
        margin-left: 15px;
        margin-right: 15px;
    }

    .home-actions {
        transform: scale(1.2);
        margin-left: 15px;
        margin-right: 15px;
    }
}

@media (max-width: 1280px) {
    .lg-overflow-scroll {
        overflow: scroll;
    }
}

@media (max-width: 1000px) {
    .md-overflow-scroll {
        overflow: scroll;
    }
}

@media (min-width: 1410px) {
    .mainCards {
        height: 300px;
        width: 450px;
        margin-left: 40px;
        margin-right: 40px;
    }

    .mainCards h3,
    .mainCards h2 {
        display: block;
    }

    .mainCards h2 {
        margin-top: 10px;
    }
}