/* LOGIN */
.login-container {
    background-image: url(../../Assets/BACKGROUND_uno-02.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.title-welcome {
    color: #43eba2;
    font-size: 45px;
}

.errorMessage {
    color: red;
    font-size: 18px;
}

label {
    font-weight: 600;
    font-size: 20px;
}