@import url('./Calibrations/Calibrations.css');
@import url('./General/PrincipalBar.css');
@import url('./General/Responsive.css');
@import url('./General/Layout.css');
@import url('./General/Libs.css');
@import url('./Home/Home.css');
@import url('./Inventory/Inventory.css');
@import url('./Login/Login.css');
@import url('./Orders/WorkOrders.css');
@import url('./Orders/Billing.css');
@import url('./Orders/OrdersPipeline.css');
@import url('./Orders/OrderStatus.css');
@import url('./Orders/CreateEditOrder.css');
@import url('./Orders/General.css');

:root {
  --audiometer-blue: #0DCAF0;
  --audiometer-green: #00E3AF;
}

* {
  font-family: "Montserrat", serif;
}

.filter-color {
  background-color: #e5f7ff;
}

.cursor-pointer {
  cursor: pointer;
  color: rgb(8, 149, 214);
  transition: 300ms;
}

.cursor-pointer:hover {
  color: #0a46b6;
}

.only-cursor-pointer {
  cursor: pointer;
  transition: 300ms;
}

.title-color {
  background-color: #0dcaf0;
}

.bg-light-green {
  background-color: #ecfdf6 !important;
}

.list-style-none {
  list-style: none;
}

.textarea {
  resize: none;
}

.bg-order-vendors,
.bg-noise-params {
  background-color: #0081cb !important;
}

.text-align-center {
  text-align: center;
}

.checkbox {
  margin-left: 30px;
}

.display-none {
  display: none;
}

.showError {
  box-shadow: 0px 0px 3px red;
}

.float-left {
  float: left;
}

.inventory-td,
.order-td {
  height: 50px !important;
  overflow: hidden !important;
}

.actions,
.home-actions {
  cursor: pointer;
  transition: 300ms;
}

.multiline {
  word-wrap: break-word;
}

.actions:hover,
.home-actions:hover {
  transform: scale(1.2);
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(#00E3AF, #0DCAF0);
  transition: 300ms all;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}