/* LOADER */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid rgb(59, 226, 255);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(59, 226, 255) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* TITULO PRINCIPAL DE TABLAS */
.firstTitle {
  color: #0dcaf0;
  font-weight: 600;
  font-size: 25px;
}

/* TITULO SECUNDARIO */
.second-title {
  color: #00b0ff;
}

/* LINK DE AGREGAR NUEVO EN CLIENTES Y ORDENES DE VENTA */
.linkClientNew,
.linkOrderNew {
  color: rgb(8, 149, 214);
  font-weight: 500;
  padding: 10px;
  background: white;
  border: none;
  font-size: 18px;
}

/* BARRAS DE NAVEGACION PERSONALIZADAS */
.scrollContainers {
  height: 400px;
  overflow: scroll;
}

.scrollContainers::-webkit-scrollbar {
  width: 0.2rem;
}

.scrollContainers::-webkit-scrollbar-thumb {
  background-color: #0dcaf0;
}

/* CARTEL INDICATIVO DE ENTORNO */
#testing-environment,
#development-environment {
  bottom: 0;
  right: 0;
  background-color: rgba(255, 0, 0, 0.5);
}

/* PIE DE PAGINA */
footer {
  position: fixed;
  bottom: 0;
}