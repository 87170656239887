.mainCards,
.mainInfoCards {
    padding: 25px;
    height: 220px;
    width: 250px;
    border-radius: 10px;
    box-shadow: 0px 3px 7px rgb(158, 154, 154);
    transition: 300ms;
}

.mainInfoCards {
    height: auto;
}

.mainCards:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.mainCards svg {
    transform: scale(1.5);
}

#billingCard svg {
    height: 1.8rem;
}

#feedback {
    background-color: #51a8cf;
}

#comercial {
    background-color: #026b4e;
}

#ambassadors {
    background-color: #69e2ff;
}

#ranking {
    background-color: #3c6c87;
}