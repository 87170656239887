/* ESTILOS DE LIBRERIAS DE TERCEROS */

/* SWEETALERT */
.backdrop-color {
    background-color: rgba(13, 202, 240, 0.8);
}

.swal-overlay {
    background-color: rgba(0, 0, 0, 0.7) !important;
    backdrop-filter: blur(15px) !important;
}

/* REACT-TOASTIFY */
.success-toast {
    background-color: green !important;
}

.warning-toast {
    background-color: orange !important;
}

.error-toast {
    background-color: red !important;
}

.info-toast {
    background-color: rgb(54, 54, 248) !important;
}

.success-toast,
.warning-toast,
.error-toast,
.info-toast {
    color: white !important;
}

.xl_toast{
    width: 800px !important;
}