#clientSection,
#client-button,
#productSection,
#product-button,
#paymentSection,
#payment-button,
#shippingSection,
#shipping-button,
#vendorSection,
#vendor-button,
#noiseParameters-button,
#pureTone-button,
#logo-button {
    border-radius: 16px !important;
}

#searchPlacesInput {
    width: 50% !important;
    margin-bottom: 10px;
    padding: 6px;
    border-radius: 5px;
}

.bg-order-client {
    background-color: #69e2ff !important;
}

.bg-order-product {
    background-color: #43eba2 !important;
}

.bg-order-payment {
    background-color: #00b784 !important;
}

.bg-order-shipping {
    background-color: #00b0ff !important;
}

.googleMaps {
    position: relative;
    height: 400px;
    margin-bottom: 30px;
}